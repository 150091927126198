import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/SiteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-are-data-types"
    }}>{`What are "Data Types"?`}</h1>
    <p>{`In a previous chapter we explored `}<em parentName="p">{`values`}</em>{`, and decided they are just the "things" that we can access inside of our programs. But when we say "things", we really mean something like "pieces of data".`}</p>
    <blockquote>
      <p parentName="blockquote">{`In programming, `}<em parentName="p">{`values`}</em>{` are just the pieces of data we can access inside of our program.`}</p>
    </blockquote>
    <p>{`"Data" is at the heart of programming, and in programming we generally have multiple different `}<em parentName="p">{`types`}</em>{` of data (such as numbers or lists). So a `}<em parentName="p">{`data type`}</em>{` is really just a type of data 🤷.`}</p>
    <h2 {...{
      "id": "the-typeof-operator"
    }}>{`The typeof operator`}</h2>
    <p>{`Recall that an `}<em parentName="p">{`operator`}</em>{` are tools for taking one or more values and turning them into a new value (like addition). JavaScript has a `}<inlineCode parentName="p">{`typeof`}</inlineCode>{` operator (which is just "type of" condensed into a single word) that will tell us the type of any value. Let's check this operator out real quick.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=datatypes-typeof",
        "live": "true",
        "id": "datatypes-typeof"
      }}>{`$log( typeof 13 );
`}</code></pre>
    <p>{`Let's break this down a bit. We're using `}<inlineCode parentName="p">{`$log`}</inlineCode>{` to print some text to our log. We're looking at `}<inlineCode parentName="p">{`typeof 13`}</inlineCode>{` which looks at the value `}<inlineCode parentName="p">{`13`}</inlineCode>{` and gives us the `}<em parentName="p">{`data type`}</em>{` of that value. Hopefully this isn't too surprising: JavaScript is telling us that `}<inlineCode parentName="p">{`13`}</inlineCode>{` is a number!`}</p>
    <p>{`There are a handful of basic data types in JavaScript, and we'll be exploring a few of these in the upcoming chapters. We'll start with numbers, because these should be familiar to you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      